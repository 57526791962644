import React from 'react';

export default function Home() {
    return(
        <div>
            <h1 className='mt-5 fs-1'>Hello world!</h1>
            <p className='mt-5 fs-5'>
                Hier gibt es noch nichts zu sehen! <br/>
                Die Seite befindet sich momentan noch im Aufbau.<br/>
                Sollten Sie einen Link zu dieser Seite bekommen haben, geben Sie diesen bitte vollständig ein!
            </p>
        </div>
    );
};