import React from 'react';
import app1 from '../assets/img/app1.jpg';
import app2 from '../assets/img/app2.jpg';
import app3 from '../assets/img/app3.jpg';
import hc1 from '../assets/img/img_3.png';
import hc2 from '../assets/img/Logo.png';
import hc3 from '../assets/img/pt1.jpg';
import hc4 from '../assets/img/pt4.jpg';
import hc5 from '../assets/img/hc5.jpeg';

export default function KANU() {
    return(
        <div className='container col-md-6  col-sm-12'>
            <h1 className='align-center mt-5'>Kanu Bewerbung</h1><br/>
            <h3 className='mt-5'>Einleitung</h3>
            <p className='fs-5' style={{'text-align': 'justify'}}>
                Ich hatte schon länger vor eine persönliche Webseite für Bewerbungen zu bauen und um meine Projekte vorzustellen.
                Die Bewerbung für KANU hat sich hierfür als gute Möglichkeit ergeben dies nun zu tun. 
                Ich habe versucht, alles, was ich sagen möchte in die sehr begrenzte Form eines 280 Zeichen langen
                Tweets zu verpacken, aber dabei hätte ich vieles weglassen müssen und die Anforderung mit "werde kreativ" hätte ich
                auch nur schwer erfüllen können. Deswegen bewerbe ich mich jetzt über diesen Weg.
            </p><br/>

            <h3 className='text-left'>Warum bin ich der richtige für KANU?</h3>
            <p className='fs-5' style={{'text-align': 'justify'}}>
                Als Informatiker habe ich nahezu ausschließlich Erfahrung mit der technischen Umsetzung
                von Projekten. Ich habe während des letzten Semesters immer wieder an einer (web)App 
                gearbeitet, mit dem Ziel React und React-Native zu lernen, was im Studium lediglich
                sehr kurz und oberflächlich besprochen wurde. Das im folgenden gezeigte Projekt ist
                noch in der Entwicklungsphase. Es fehlen also noch einige Features und die Landing 
                Page befindet sich noch im Aufbau. Alle angezeigten Daten sind Testdaten. Wie Sie an der Webapp&nbsp;
                <a href='https://bookbanter.co' target='blank'>(www.bookbanter.co)</a>&nbsp;
                und den unten gezeigten Screenshots der App sehen können mangelt es mir nicht an dem 
                Wissen und den Fähigkeiten solche Projekte umzusetzen. Allerdings möchte ich mich 
                gerne tiefer mit Themen wie Marketing, Sales, etc. beschäftigen, da ich in diesen 
                Bereichen noch relativ wenig Erfahrung sammeln konnte. <br/><br/>
                <div className='d-flex align-items-start'>
                    <img style={{width: '30%', marginRight: '5%', borderRadius: 20}} src={app1} alt='app screenshot 1'/> 
                    <img style={{width: '30%', marginRight: '5%', borderRadius: 20}} src={app2} alt='app screenshot 2'/> 
                    <img style={{width: '30%', borderRadius: 20}} src={app3} alt='app screenshot 3'/><br/><br/>
                </div> <br/>
                Ich versuche mich bereits seit längerer Zeit in das Thema Gründung einzuarbeiten. 
                Letztes Jahr habe ich mit zwei Studenten aus der Fakultät Maschinenbau am 5€ 
                Business Wettbewerb teilgenommen und dabei den zweiten Platz belegt. Im Zuge dessen
                waren wir unter anderem bei Allgäu Digital zu besuch, wo wir deren Räumlichkeiten besichtigt
                haben und uns mit den Gründern des Startups Cartilago ausgetauscht haben.
                Aus verschiedenen Gründen haben wir uns dann allerdings dazu entschieden das Projekt zu beenden.
                Neben diesem Projekt war ich auch bei der letztjährigen Allgäuer Gründerbühne.
                <br/><br/>
                <div className='w-100 h-auto d-flex align-items-start'>
                    <div style={{width: '30%', marginRight: '5%'}}>
                        <img style={{width: '100%', borderRadius: 20}} src={hc1} alt='5€ Business 1'/><br/>
                    </div>
                    <div style={{width: '30%', height: 'auto', marginRight: '5%', display: 'flex', flexDirection: 'column', justifycontent: 'space-between'}}>
                        <img style={{width: '100%', marginBottom: '12%', borderRadius: 20}} src={hc2} alt='5€ Business 2'/><br/>
                        <img style={{width: '100%', marginBottom: '12%', borderRadius: 20}} src={hc3} alt='5€ Business 3'/><br/>
                        <img style={{width: '100%', borderRadius: 20}} src={hc4} alt='5€ Business 4'/><br/>
                    </div>
                    <div style={{width: '28%'}}>
                        <img style={{width: '100%', borderRadius: 20}} src={hc5} alt='5€ Business 5'/><br/>
                    </div>
                </div><br/><br/>
                Außerdem bin ich in mehreren Online Communities aktiv, wodurch ich einen anderen Teilnehmer 
                vom 5€ Business Wettbewerb (aus einem früheren Jahr) kennengelernt habe. Wir haben uns erst 
                vor einigen Wochen an der Hochschule getroffen und uns über das Thema Gründung unterhalten.
                <br/><br/>
                Ich hoffe ich konnte Sie mit dieser Bewerbung überzeugen. Ich freue mich bald wieder von 
                Ihnen zu hören.
                <br/><br/>
                Mit freundlichen Grüßen<br/>
                Tobias Stöffel
            </p><br/>
        </div>
    );
};