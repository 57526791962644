import './App.css';
import { React } from 'react';
import { Route, Routes } from "react-router-dom";

import Home from './pages/Home';
import KANU from './pages/KANU';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="" element={<Home/>} />
        <Route exact path="KANU" element={<KANU/>} />
      </Routes>
    </div>
  );
}

export default App;
